* {
    box-sizing: border-box;
}
.bgImg {
    background: url('./V1\ second.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100%;
}

.container {

    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    max-width: 500px;
    width: 100%;
    margin: auto;
}

.container > form {
    width: 70%;
    border: 1px solid #dfdfdf;
    padding: 20px;
    border-radius: 5px;
    background: #fff;
}

input {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-left: 25px;
    border-radius: 9%;
}

pre {
    width: 70%;
    color: #fff;
    font-size: larger;
}

button {
    background: #05b462 !important;
    padding: 5px;
    border-radius: 10px;
   cursor: pointer;
}

p {
    color: rgb(255, 0, 0);
}

.text {
    margin: 2px;
    padding: 5px;
}

span{
    color: rgb(0, 95, 236);
    cursor: pointer;
}